





















































import Vue from 'vue';
import CreateMembershipRequestViewModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    periodStart: undefined as string | undefined,
    periodEnd: undefined as string | undefined,
    proRataAmount: undefined as number | undefined,
    calculatedCost: 240,
    socialInsuranceTypes: [] as any[],
  }),
  computed: {
    compensationOfficeItems(): {caption: string, id?: any}[] {
      return [
        {
          caption: this.$t('page.createMembership.select.noEntry'),
        },
        {
          caption: 'Test',
          id: 1,
        },
      ];
    },
    certificateOfProficiencyCantonItems(): {caption: string, id?: any}[] {
      return [
        {
          caption: this.$t('page.createMembership.select.noEntry'),
        },
      ];
    },
    innerValue: {
      get(): CreateMembershipRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    'innerValue.entryDate': {
      immediate: true,
      handler() {
        this.calculateProRata();
      },
    },
  },
  async beforeMount() {
    const listInsurances = await this.$service.api.attributes.getSocialInsuranceTypes();
    this.socialInsuranceTypes = listInsurances.items;
    if (!this.innerValue.entryDate || this.innerValue.entryDate === '') {
      const nextMonth = this.$date.now().add(1, 'month');
      this.innerValue.entryDate = this.$date(`${nextMonth.year()}-${nextMonth.month()}-1`).format('YYYY-MM-DD');
    }
  },
  methods: {
    async calculateProRata() {
      const proRataBasket = await this.$service.api.memberships.calculateMembershipByEntryDate(this.innerValue.entryDate!, this.innerValue.orderBasket);
      this.proRataAmount = proRataBasket.grossTotal;
      this.periodEnd = proRataBasket.periodEnd!;
      this.periodStart = proRataBasket.periodStart!;
    },
  },
});
